import {
  Container,
  InnerContainer,
  Bloco,
  ContainerButtons,
  Button,
  ButtonImage,
  Footer,
  TextFooterClassification,
  Partners,
  Play,
  Fone,
  Gandaya,
  Aldeia,
  Afago,
  Gastromotiva
} from './styles'

import bloco from '../../assets/images/bloco.png'
import botao_rio from '../../assets/images/botao_rio.png'
import botao_sp from '../../assets/images/botao_sp.png'
import parceiros from '../../assets/images/parceiros.png'
import play_vector_home from '../../assets/images/play_vector_home.png'
import fone from '../../assets/images/fone.png'

function Home(){
  return(
    <Container>
      <InnerContainer>
        <Bloco src={bloco}/>
        <ContainerButtons>
          <Button to="/rio">
            <ButtonImage src={botao_rio}/>
          </Button>
          <Button to="/sampa">
            <ButtonImage src={botao_sp}/>
          </Button>
        </ContainerButtons>
        <Fone src={fone} /> 
        <Aldeia href="https://www.aldeiasinfantis.org.br"/>
        <Afago href="https://www.afagosp.org.br"/>
        <Gastromotiva href="https://gastromotiva.org/"/>
      </InnerContainer>
      <Footer bgColor="#4F1F9E">
        <TextFooterClassification
          style={{fontSize:"16px"}}
        >A classificação etária do Festival é de 18 anos.</TextFooterClassification>
        <TextFooterClassification
          style={{fontSize:"14px"}}
        >Menores de idade somente acompanhados de responsável.</TextFooterClassification>
        <Play src={play_vector_home}/>
      </Footer>
      <Footer 
        bgColor="#1D1D1B"
        style={{flexDirection:"row", justifyContent:"space-evenly"}}
        >
        <Partners src={parceiros} alt="Parceiros" />
      </Footer>
      
    </Container>
  )
}

export default Home;