import React from 'react';

import {
	Routes,
	Route,
	useLocation,
} from "react-router-dom";

import { Container } from './styles';

// Pages
import Home from './pages/Home';
import Rio from './pages/Rio';
import Sampa from './pages/Sampa';

const App = () => {
	const location = useLocation();

	return (
		<Container>
			
			<Routes location={location} key={location.pathname}>
				<Route exact path="/" element={<Home />} />
				<Route exact path="/rio" element={<Rio />} />
				<Route exact path="/sampa" element={<Sampa />} />
			</Routes>
			
		</Container>
	);
}


export default App;
