import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  height: 100%;
  background-color: #B11DFF;
  
  display: flex;
  flex-direction: column;
  align-items: center;

  overflow-y: scroll;
  overflow-x: hidden;

  z-index: -2;
`

export const InnerContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  max-width: 500px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

export const Bloco = styled.img`
  width: 100%;
`

export const Button = styled.a`
  margin-top: 20px;
  width: 72%;
`

export const ButtonImage = styled.img`
  width: 100%;
`

export const Fire = styled.img`
  position: absolute;
  width: 100%;

  z-index: -1;

  bottom: 0;
`