import {
  Container,
  InnerContainer,
  Bloco,
  Button,
  ButtonImage,
  Fire,
} from './styles'

import bloco_rio from '../../assets/images/bloco_rio.png'
import sympla_rio from '../../assets/images/sympla_rio.png'
import fire from '../../assets/images/fire.png'

function Rio(){

  return(
    <Container>
      <InnerContainer>
        <Bloco src={bloco_rio}/>
        <Button href='https://www.sympla.com.br/replayrj'>
          <ButtonImage src={sympla_rio}/>
        </Button>
      </InnerContainer>
      <Fire src={fire}/>
    </Container>
  )
}

export default Rio