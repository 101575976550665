import {
  Container,
  InnerContainer,
  Bloco,
  Button,
  ButtonImage,
  Fire
} from './styles'

import bloco_sp from '../../assets/images/bloco_sp.png'
import sympla_sp from '../../assets/images/sympla_sp.png'
import fire from '../../assets/images/fire.png'

function Rio(){

  return(
    <Container>
      <InnerContainer>
        <Bloco src={bloco_sp}/>
        <Button href='https://www.sympla.com.br/replaysp'>
          <ButtonImage src={sympla_sp}/>
        </Button>
      </InnerContainer>
      <Fire src={fire}/>
    </Container>
  )
}

export default Rio