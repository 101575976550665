import { Link } from 'react-router-dom'
import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  /* height: 100%; */
  background-color: #18C9F3;
  
  display: flex;
  flex-direction: column;
  align-items: center;

  overflow-y: scroll;
  overflow-x: hidden;

  z-index: -2;
`

export const InnerContainer = styled.div`
  position: relative;
  width: 100%;
  max-width: 500px;

  display: flex;
  flex-direction: column;
  align-items: center;

  padding-bottom: 40px;
`

export const Bloco = styled.img`
  width: 100%;
`

export const ContainerButtons = styled.div`
  width: 100%;
  max-width: 500px;

  display: flex;
  justify-content: space-evenly;

  margin-top: 30px;
`

export const Button = styled(Link)`
  width: 45%;
`

export const ButtonImage = styled.img`
  width: 100%;
`

export const Footer = styled.div`
  position: relative;

  width: 100%;
  padding: 20px 0;

  background-color: ${props => props.bgColor};

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

export const TextFooterClassification = styled.p`
  font-family: "IdealSans";
  margin: 5px 0;
  text-align: center;
`

export const Partners = styled.img`
  width: 80%;
  max-width: 500px;
`

export const Play = styled.img`
  position: absolute;
  z-index: -1;
  bottom: 96px;
  width: 100%;
`

export const Fone = styled.img`
  position: absolute;
  z-index: -1;
  width: 400px;
  right: 0;
  top: 50px;
  transform: rotate(-45deg) translate(50%, 50%);
`

export const Aldeia = styled.a`
  width: 24%;
  height: 1.1%;
  position: absolute;

  top: 31.2%;
  left: 21%;

  /* background-color: red; */
`

export const Afago = styled.a`
  width: 24%;
  height: 1%;
  position: absolute;

  top: 32.3%;
  left: 21%;

  /* background-color: green; */
`

export const Gastromotiva = styled.a`
  width: 20%;
  height: 1.1%;
  position: absolute;

  top: 31.5%;
  left: 46%;

  /* background-color: blue; */
`